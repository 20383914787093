import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { user as userSlice} from './redux/userSlice';
import axios from 'axios';
import Utility from './myComponents/helper/utility';

// Request Interceptor
axios.interceptors.request.use((request)=>{
  const util = new Utility();
  const accessToken = util.getCookie('_at'); 
  const publicKey = localStorage.getItem('publicKey');

  if(accessToken){
      request.headers['Authorization'] = `Bearer ${accessToken},${publicKey}`;
  }else{
    request.headers["Authorization"] = publicKey;
  }
  return request;
});

// Response interceptor
const {dispatch} = store;
axios.interceptors.response.use((response)=>{
  const util = new Utility();
  const backEndErrorCode = response.data.errorCode;
 
  if(backEndErrorCode && backEndErrorCode === 'INVALID_OR_EXPIRED_TOKEN'){
    localStorage.removeItem('_u');
    util.deleteCookie('_at');  
    dispatch(userSlice(null));
  }

  return response;
});

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <App/>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
