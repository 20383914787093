import React from 'react';
import Utility from '../helper/utility';
import DecodeJWT from '../decodeJWT';  
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { user as userReducer} from '../../redux/userSlice';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import Skeleton from 'react-loading-skeleton';

const LoginLink = (props) => {
    const util = new Utility();
    // const referrerSlug = util.getCookie('_referrerSlug');

    let [ res, setRes] = useState(null);
    let [ canDecodeToken, setCanDecodeToken] = useState(false);
    let [isProcessing, setIsProcessing] = useState(false);
    let dispatch = useDispatch();

    const user = useSelector((state)=> state.user);

    useEffect(()=>{
        if(!user && util.getDataInLocalStorage('_u')){
            dispatch(userReducer(JSON.parse(util.getDataInLocalStorage('_u'))));
        }
    }, []);

    useEffect(()=>{
        authenticateUser();
    }, []); 

    const authenticateUser = async () =>{   
        const querySearch = window.location.search;
        const d  = querySearch.substring(3, querySearch.length);

        if(d && typeof d == 'string' && d.length > 30){                              
            let qpObj = util.convertEncryptedQueryStringsToObject(d);

            if(!qpObj || typeof qpObj != 'object'){
                return;
            }
            
            let { accessToken, refreshToken } = qpObj;
            let referrerSlug = util.getCookie('_referrerSlug');

            if(accessToken && typeof accessToken == 'string' && accessToken.length > 0 && refreshToken && typeof refreshToken == 'string' && refreshToken.length > 0){             
                setIsProcessing(true);
                try{
                    const res = await axios
                    .post(`${process.env.REACT_APP_API_PATH}auth/doAuthorization`, 
                    {
                        gfs7AccessToken: accessToken, 
                        referrerSlug
                    });
                    setIsProcessing(false);
    
                    const {error, data} = res.data;
                        
                    if(!error && data){
                        const { accessToken, refreshToken } = data;
                        if(accessToken && refreshToken){
                            util.setCookie('_at', accessToken);    
                            util.deleteCookie('_referrerSlug');
                            setRes(data);
                            setCanDecodeToken(true);
                        }
                    }
                }catch(err){
                    console.error(err);
                }
            }else{
                // console.log('no access token')
            }                  
        }      
    }

    const handleSetUser = (user) =>{
        if(user){
            window.history.pushState({}, document.title, "/"); // clears the query params from the url
            setCanDecodeToken(false);
            dispatch(userReducer(user));

            props.history.push(`/`);
        }else{
            dispatch(userReducer(null));
        }
    }

    if(res){
        const { accessToken } = res;
    
        if(accessToken && canDecodeToken){
            return <DecodeJWT params={ {token: accessToken} } handleSetUser={handleSetUser}/>             
        }    
    }

    if(isProcessing){
        return <Skeleton count={3} />
    }else{
        
        return null;
    }}
export default withRouter(LoginLink);