import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WhatsappUs from'../contactUs/whatsappUs';
import axios from 'axios';

const Footer = () =>{
    const merchant = useSelector((state)=> state.merchant) || {};
    const user = useSelector((state)=> state.user); 

    return (
      <footer className="page-footer font-small pt-4" style={{background: '#eee'}}>
        <div className="container text-center text-md-left">
  
          <div className="row text-center text-md-left mt-3 pb-3">
            {
              merchant.canShowAgentRegLink && 
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">BECOME AN AGENT</h6>
                <p>Do you want to become an agent and earn money everyday? Click <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhA4oFhWFef2HSqfIgFhvHT_bO966nLWuQaR0PGXAKJqyuRA/viewform?fbzx=-4061438569570223316" target="_blank" rel="noopener noreferrer">Agent Registration Form</a> to get started</p>
              </div>
            }
  
            <hr className="w-100 clearfix d-md-none" />
  
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Products</h6>
              <p>
                <Link to="/data-plans">Data</Link>
              </p>
              <p>
                <Link to="/">Cable Subscriptions such as DSTV, GOTV, STARTIMES etc</Link>
              </p>
              <p>
                <Link to="/">Utility bills such as EKEDC, EBDC etc</Link>
              </p>
              <p>
              <Link to="/airtime/buy">AirTime</Link>
              </p>
            </div> 
  
            <hr className="w-100 clearfix d-md-none" />
  
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Useful links</h6>
              <p>
                <a  href="https://greatftech.com" target="_blank" rel="noopener noreferrer">Great FTech</a>
              </p>            
              <p>
                    <a 
                      href="#/"
                      onClick={async ()=>{
                        if(user){
                          try{
                            const res = await axios.get(`${process.env.REACT_APP_API_PATH}user/get-auto-login-key`);
                            const { error, data } = res.data;
    
                            if(!error && data){
                              window.location.href=`https://dashboard.ekki.com.ng/auto-login?k=${data}&r=`
                            }else{
                              window.location.href='https://dashboard.ekki.com.ng'
                            }
    
                          }catch(err){
                            window.location.href='https://dashboard.ekki.com.ng'
                          }
                        }else{
                          window.location.href='https://dashboard.ekki.com.ng'
                        }
                      }} 
                      target="_blank" 
                      rel="noopener noreferrer">Ekki - Dashboard
                    </a>
  
              </p>            
              <p>
                <a href="https://gospel.greatftech.com" target="_blank" rel="noopener noreferrer">Gospel</a>
              </p>
              <p>
                <a href="https://greatftech.com/legal/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>
              </p>

              <p>
                <a href="https://greatftech.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </p>

              <p>
                <a href="https://greatftech.com/legal/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
              </p>

              <p>
                <Link to="/policy/return">Return Policy</Link>
              </p>

            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Developers</h6>
              <p>
                <a href={process.env.REACT_APP_API_DOC_LINK} target="_blank" rel="noreferrer">Api Documentation</a>
              </p>
            </div>            
  
            <hr className="w-100 clearfix d-md-none" />
  
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
              {
                [merchant.address].map((address, i)=>{
                  return <p key={i}><i className="fas fa-home mr-3"></i>{ address }</p>
                })
              }
              {
                [merchant.supportEmail].map((email, i)=>{
                  return <p key={i}><i className="fas fa-home mr-3"></i>{ email }</p>
                })
              }
              {
                [merchant.supportPhone].map((phone, i)=>{
                  return <p key={i}><i className="fas fa-home mr-3"></i>{ phone }</p>
                })
              }
              {
                [merchant.supportWhatsapp].map((whatsapp, i)=>{
                  return <p key={i}><i className="fas fa-home mr-3"></i>{ whatsapp }</p>
                })
              }
            </div>
          </div>
  
          <hr />
  
          <div className="row d-flex align-items-center">
  
            <div className="col-md-7 col-lg-8">
  
              <p className="text-center text-md-left">&copy; { new Date().getFullYear() } Copyright:
                <Link to="/">
                  <strong> { merchant.appName }</strong>
                </Link>
              </p>
            </div>
  
            <div className="col-md-5 col-lg-4 ml-lg-0">
  
              <div className="text-center text-md-right">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <button className="btn-floating btn-sm rgba-white-slight mx-1">
                      <i className="fab fa-facebook-f"></i>
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <button className="btn-floating btn-sm rgba-white-slight mx-1">
                      <i className="fab fa-twitter"></i>
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <button className="btn-floating btn-sm rgba-white-slight mx-1">
                      <i className="fab fa-google-plus-g"></i>
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <button className="btn-floating btn-sm rgba-white-slight mx-1">
                      <i className="fab fa-linkedin-in"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {
          merchant.supportWhatsapp && 
          <WhatsappUs />
        }

      </footer>
    )
  }

export default Footer;