import React from 'react';
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'

const QuickLoginForm = () => {
  return (
    <CRow>
        <CCol sm="12 mx-auto">
            <CCard>                
                <CCardBody>
                    <div>Hi, Thanks for registering. You need to verify your email</div>

                    <div>
                      <h3 className="my-2">Check your mailbox</h3>
                      <div>We’ve sent an email to you with a link to confirm your account.</div>
                      <div>Didn't get the email?</div>
                      <div className="my-2">
                          If you don’t see an email from us within 5 minutes, one of these things could have happened:
                      </div>
                      <div> 
                          <ol>
                              <li>The email is in your spam folder.</li>
                              <li>The email address you entered had a typo..</li>
                              <li>You accidentally entered another email address. (Usually happens with auto-complete..</li>
                              <li>We can’t deliver the email to this address. (Usually because of corporate firewalls or filtering.)</li>
                          </ol>
                      </div>
                  </div>
                </CCardBody>
            </CCard>
        </CCol>    
    </CRow>
  )
}

export default QuickLoginForm;
