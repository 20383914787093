import React from 'react'
import { Navbar, Container, Nav, NavDropdown} from 'react-bootstrap'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Logo  from '../logo'; 
import { Link } from 'react-router-dom';
import CopyText from '../helper/copyText';
import RegisterUserLink from '../auth/registerUserLink';
import QuickLoginForm from '../auth/quickLoginForm';
import Register from '../auth/register';
import Logout from '../auth/logout';
import WalletBalance from '../wallet/walletBalance';

const navStyles = {
  marginRight: 10,
  marginTop: 5,
  fontSize: 20,
}

const TheHeader = () =>{
    const [showModal, setShowModal] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const merchant = useSelector((state)=> state.merchant) || {};
    const user = useSelector((state)=> state.user);

    const doAccountUpgrade = () => {

    }

    return(
      <Navbar className="border-bottom" expand="sm"> 
        <Container>  
          <Navbar.Brand>
            <Link to="/">        
              <Logo/> 
              <strong className='m-2'>{ merchant.appName }</strong>
            </Link>
          </Navbar.Brand>
          <Navbar.Brand>
            {user && <span>{user.firstName} ({user.userCategoriesSlug }) </span>}
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav style={navStyles}><a href="https://docs.data.greatftech.com" target ="_blank" rel="noreferrer noopener">|Guide</a></Nav> 
              <Nav style={navStyles}><Link to="/data-plans">|Data</Link></Nav>                                                                                          
              <Nav style={navStyles}><Link to="/airtime/buy">|Airtime</Link></Nav>
              <Nav style={navStyles}><Link to="/wallet/fund">|FundWallet</Link></Nav>
              {
                user && 
                <Nav style={navStyles}><Link to="/wallet/history">|History</Link></Nav>
              }

              {
                user && merchant.showAgentRegLink && 
                <Nav style={navStyles}>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhA4oFhWFef2HSqfIgFhvHT_bO966nLWuQaR0PGXAKJqyuRA/viewform" target="_blank" rel="noreferrer noopener"  onClick={ doAccountUpgrade }>|Upgrade</a>
                </Nav>
              }  

              <Nav style={navStyles}><a href={process.env.REACT_APP_API_DOC_LINK} target={'_blank'} rel="noreferrer">|API</a></Nav>
              <Nav style={navStyles}><Link to="/contact-us">|Contact</Link></Nav>            
              <Nav style={navStyles}><Link to="/about-us">|About</Link></Nav>
              {
                !user &&
                  <Nav onClick={ ()=>{ setShowModal(true); setShowLogin(true); setShowRegister(false) } }><strong className='bg-dark py-2 px-3'>Login</strong></Nav>            
              }
              
              { 
                (user && user.profileImgPath) &&
                  <img src={ user.profileImgPath } alt="" width="40" height="40" className="rounded-circle ml-5" />
              }
  
              {
                user &&
                <NavDropdown title="More" id="basic-nav-dropdown">

                  <WalletBalance />

                  <NavDropdown.Item>
                    <Logout />
                  </NavDropdown.Item>   

                </NavDropdown> 
              }   

              { !user && <div className="py-2 mx-sm-1"><RegisterUserLink /></div>  }
            </Nav>
          </Navbar.Collapse>
          {
            user &&
            <div className='col-12 col-sm-9 text-center'>          
                <Navbar.Brand>
                  <span>{ user.referralLink ? <span className="pl-3"><CopyText params={{textToCopy: user.referralLink, buttonText: 'Copy Your ReferralLink'}} /></span> : null}</span>              
                </Navbar.Brand>
            </div>
          }
        </Container>
        <>
          <Modal
            show={showModal}
            onHide={()=> setShowModal(false)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Logo />
              <Modal.Title>{ showLogin && 'Login' } { showRegister && 'Register Now' } </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { showLogin && <QuickLoginForm setShowModal={setShowModal} setShowLogin={setShowLogin } setShowRegister={setShowRegister} /> }
              { showRegister && <Register setShowModal = { setShowModal } setShowLogin = {setShowLogin } setShowRegister = {setShowRegister} /> }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=> setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Navbar>
    );
} 

export default TheHeader;