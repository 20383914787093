import React from 'react';
import { useSelector } from 'react-redux';
import Sliders from './sliders';
import Faqs from './faqs';
import PeopleFeedback from './feedback/peopleFeedback';
import Announcement from './notifications/announcements';
import News from './news/news';
import DataPlansByNetwork from './data/dataPlansByNetwork';
import BuyAirtime from './airtime/buyAirtime';

const Home = () => { 
    const merchant = useSelector((state)=> state.merchant) || {};

    return ( 
        <div>
            {
                merchant.showNews ?
                <News /> : ""
            }

            {
                merchant.showAnnouncement ?
                <Announcement /> : ""
            }            
            
            {
                merchant.showSliders ? <Sliders /> : ""
            }
        
            {
                merchant.slug && 
                <>
                    <div className="pt-5">
                        <DataPlansByNetwork  />
                    </div>
                    <div className="py-5">
                        <BuyAirtime  />
                    </div>
                </>
            }

            {
                merchant.showFeedBacks && <PeopleFeedback />
            }
            {
                merchant.showFaq && <Faqs />
            }
        </div>      
    );
}

export default Home;
