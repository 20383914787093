import React from 'react';
import logo from '../assets/img/logos/datalab_logo.gif'; // with import

const Logo = () => {
    const logoStyle = {
        width: 30+'px',
        height: 30+'px',            
    }

    return (            
        <img src={logo} alt="Logo" style={ logoStyle } />
    );
    
}

export default Logo;